import React, {useRef, useEffect, useState} from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import getColorStr from "../../color/color";
import {Image, Title, Line, Glass} from "../../";
import Tiles from "../tiles/tiles";

import * as s from "./category.module.css";

const IndexCategory = ({ title, description, image, items, section, layout = 1 }) => {
  const ref= useRef();
  const [isEnter, setIsEnter] = useState(false);

  useEffect(() => {
    const trigger = ScrollTrigger.create({
      trigger: ref.current,
      start: `0% 70%`,
      end: `0% 70%`,
      once: true,
      onEnter: () => setIsEnter(true)
    })
    return () => trigger.kill()
  }, [ref, setIsEnter])

  const color = image.asset.metadata.palette.muted.background;

  return (
  <section ref={ref} className={s.section}>
    <div className={`${s.descWrapper} ${s["layout" + layout]}`}>
      <Image
        image={image}
        className={s.image}
        alt=""
        isReady={isEnter}
        width={1200}
        sizes={`
          (min-width: 1600px) 1200px,
          (min-width: 1280px) 900px,
          (min-width: 960px) 600px,
          (min-width: 768px) 450px,
          300px
        `}
      />
      <Glass className={s.glass} color={color} ready={isEnter} />
      <Title
        className={s.title}
        value={title}
        component={"h2"}
        isReady={isEnter}
        animationStep={100}
        animationDuration={300}
        color={getColorStr(color, 20, 90, 1)}
      />
      <Line className={s.line} isReady={isEnter} />
      <div className={s.description}>
        <p>{description}</p>
      </div>
    </div>
    <Tiles className={s.listWrapper} items={items} section={section} isReady={isEnter} />
  </section>
)}

export default IndexCategory