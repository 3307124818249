import React, { useRef, useEffect, useState } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";
import getColorStr from "../../color/color";
import {Image, Title, Line, Button} from "../../";

import * as s from "./feature.module.css";

const IndexFeature = ({image, title, link, to, children}) => {
  const ref = useRef();
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    const trigger = ScrollTrigger.create({
      trigger: ref.current,
      start: `0% 70%`,
      end: `0% 70%`,
      once: true,
      onEnter: () => setIsReady(true)
    })
    return () => trigger.kill();
  }, [ref, setIsReady])

  const {background} = image.asset.metadata.palette.muted;
  const color = getColorStr(background, 20, 90, 1);
  return (
  <section ref={ref} className={s.section}>
    <Image
      image={image}
      className={s.image}
      alt=""
      width={700}
      isReady={isReady}
      sizes={`
        (min-width: 1600px) 700px,
        (min-width: 1280px) 475px,
        (min-width: 960px) 350px,
        (min-width: 768px) 260px,
        260px
      `}
    />
    <div className={s.content}>
      <Title
        className={s.title}
        component="h2"
        value={title}
        color={color}
        isReady={isReady}
      />
      <Line isReady={isReady} />
      <div className={s.description}>
        {children}
      </div>
      <Button to={to} label={link} />
    </div>
  </section>
)}

export default IndexFeature