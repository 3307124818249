// extracted by mini-css-extract-plugin
export var descWrapper = "category-module--desc-wrapper--b57fe";
export var description = "category-module--description--ce380";
export var glass = "category-module--glass--b4a5a";
export var image = "category-module--image--0f07b";
export var layout1 = "category-module--layout-1--c855b";
export var layout2 = "category-module--layout-2--7a4df";
export var line = "category-module--line--1ad32";
export var listWrapper = "category-module--list-wrapper--8b1fc";
export var section = "category-module--section--ef4c9";
export var title = "category-module--title--fda33";