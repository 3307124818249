import React from "react";
import { graphql } from "gatsby";

import {Layout} from "../components";
import {Hero, Category, FeaturesWrapper, Feature} from "../components/indexPage";


const IndexPage = ({data}) => {
  const d = data.sanityHome;
  return (
  <Layout
    seo={{
      title: d.seoTitle,
      description: d.seoDescription
    }}
    floatingHeader={true}
    headerTheme={"dark"}
  >
    <Hero
      title={d.heroTitle}
      subtitle={d.heroSubtitle}
      description={d.heroDescription}
    />
    <Category
      image={d.servicesImage}
      title={d.servicesTitle}
      description={d.servicesDescription}
      items={data.allSanityService.nodes}
      section="services"
    />
    <Category
      image={d.platformsImage}
      title={d.platformsTitle}
      description={d.platformsDescription}
      items={data.allSanityPlatform.nodes}
      section="platforms"
      layout={2}
    />
   
    
    <FeaturesWrapper color={[
      d.industriesImage.asset.metadata.palette.muted.background,
      d.teamImage.asset.metadata.palette.muted.background
    ]}>
     <Feature
        image={d.industriesImage}
        title={d.industriesTitle}
        link={d.industriesCta}
        to="/industries"
      >
        <p>{d.industriesDescription}</p>
      </Feature>
      <Feature
        image={d.teamImage}
        title={d.teamTitle}
        link={d.teamCta}
        to="/about"
      >
       <p>{d.teamDescription}</p>
      </Feature>
     
    </FeaturesWrapper> 
  </Layout>
)}

export const pageQuery = graphql`
  query HomePageQuery {
    sanityHome {
      heroTitle
      heroSubtitle
      heroDescription

      servicesTitle
      servicesDescription
      servicesImage {
        ...ImageWithPreview
        asset {
          metadata{
            dimensions {
              aspectRatio
            }
            palette {
              muted {
                background
              }
            }
          }
        }
        crop {
          bottom
          left
          right
          top
        }
      }
      
      platformsTitle
      platformsDescription
      platformsImage {
        ...ImageWithPreview
        asset {
          metadata{
            dimensions {
              aspectRatio
            }
            palette {
              muted {
                background
              }
            }
          }
        }
        crop {
          bottom
          left
          right
          top
        }
      }
      
      industriesTitle
      industriesDescription
      industriesImage {
        ...ImageWithPreview
        asset {
          metadata{
            dimensions {
              aspectRatio
            }
            palette {
              muted {
                background
              }
            }
          }
        }
        crop {
          bottom
          left
          right
          top
        }
      }
      industriesCta
      
      teamTitle
      teamDescription
      teamImage {
        ...ImageWithPreview
        asset {
          metadata{
            dimensions {
              aspectRatio
            }
            palette {
              muted {
                background
              }
            }
          }
        }
        crop {
          bottom
          left
          right
          top
        }
      }
      teamCta

      seoTitle
      seoDescription

    }

    allSanityService(sort: {fields: orderRank}) {
      nodes {
        slug {
          current
        }
        title
        subtitle
        icon {
          asset {
            url
          }
        }
      }
    }
    allSanityPlatform(sort: {fields: orderRank}) {
      nodes {
        slug {
          current
        }
        title
        subtitle
        icon {
          asset {
            url
          }
        }
      }
    }
  }
`


export default IndexPage
