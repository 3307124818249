import React from 'react'
import {Glass} from '../../'

import * as s from './featuresWrapper.module.css'

const IndexFeaturesWrapper = ({children, color}) => (
  <div className={s.wrapper}>
    {children}
    <Glass className={s.glass} color={color} />
  </div>
)

export default IndexFeaturesWrapper