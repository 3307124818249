import React, {useState, useRef, useEffect} from "react"
import {Title} from "../../"

import mp2160 from "./intro_2160.mp4"
import mp1440 from "./intro_1440.mp4"
import mp1080 from "./intro_1080.mp4"
import mp720 from "./intro_720.mp4"
import mp480 from "./intro_480.mp4"

import * as s from "./hero.module.css"

const videos = {
  2160: {mp4: mp2160},
  1440: {mp4: mp1440},
  1080: {mp4: mp1080},
  720: {mp4: mp720},
  480: {mp4: mp480}
}

const IndexHero = ({title, subtitle, description}) => {
  const [vid, setVid] =  useState();
  const ref = useRef();

  useEffect(() => {
    const getVideo = () => {
      const w = window.innerWidth;
      if (w >= 1920) {
        setVid(videos[2160])
      } else if (w >= 1440) {
        setVid(videos[1440])
      } else if (w >= 1024) {
        setVid(videos[1080])
      } else if (w >= 600) {
        setVid(videos[720])
      } else {
        setVid(videos[480])
      }
    }
    getVideo();
    window.addEventListener("resize", getVideo);
    return () => window.removeEventListener("resize", getVideo)
  }, [])
  useEffect(() => {
    ref.current && ref.current.load()
  }, [vid])
  
  return (
  <section className={s.section}>
    { vid ? (
    <video poster="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3C/svg%3E" ref={ref} className={s.image} muted autoPlay loop playsInline disablePictureInPicture>
      <source src={vid.mp4} type="video/mp4" />
    </video>
    ) : <div className={s.image} />}
    <div className={s.content}>
      <Title value={title} className={s.title} />
      <p className={s.subtitle}>{subtitle}</p>
      <p className={s.description}>{description}</p>
    </div>
  </section>
)}

export default IndexHero